





































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class Unit extends Vue {
  private name = "Machine";
  private modulename = "machine";
  private selectedItem: any = null;
  private tempItem: any = null;
  private data: any = { name: "", description: "" };

  @Watch('mode')
  modeChanged(value: string) {
    this.clear();
  }

  save () {
    if (this.mode === 'create') {
      this.$store.dispatch(`${this.modulename}/create`, { data: this.data, callback: this.onSaved });
    } else if (this.mode === 'alter') {
      const data = { ...this.data }
      const id = data._id;
      delete data._id;
      this.$store.dispatch(`${this.modulename}/patch`,{ id, data, callback: this.onSaved });
    }
  }

  remove () {
    if (this.selectedItem) {
      this.$confirm(`This will remove this ${this.name}, Do you want to continue`, "Confirm", { confirmButtonText: 'Yes', cancelButtonText: 'No', type: 'warning'})
      .then(() => {
        this.$store.dispatch(`${this.modulename}/remove`,{ id: this.selectedItem, callback: this.onRemoved });
      })
      .catch(() => {})
    }
  }

  onSaved (e: any, d: any) {
    let msg = ""
    if (d) {
      this.clear();
      msg  = this.mode === "create" ? `${this.name} Successfully Created!` : `${this.name} Successfully Updated!`;
      this.$store.commit("SHOW_SUCCESS", msg)
    } else {
      msg = e.message;
      this.$store.commit("SHOW_ERROR", msg)
    }
  }

  onRemoved (e: any, d: any) {
    let msg = ""
    if (d) {
      this.clear();
      msg  = `${this.name} Successfully Removed!`;
      this.$store.commit("SHOW_SUCCESS", msg)
    } else {
      msg = e.message;
      this.$store.commit("SHOW_ERROR", msg)
    }
  }

  clear () {
    this.data = { name: "", description: "" }
    this.selectedItem = null;
    this.tempItem = null;
  }

  selectObject (sitem: any) {
    this.selectedItem = sitem;
    if (this.selectedItem) {
      const item = this.items.filter((i: any) => i._id && i._id.toString() === this.selectedItem.toString())[0];
      if (item) {
        this.data = JSON.parse(JSON.stringify(item));
      }
    }
  }

  get items () {
    const items = this.$store.state[this.modulename].items;
    return items;
  }

  get canSave () {
    return this.data.name
  }

  get canRemove () {
    return this.selectedItem
  }

  get mode () {
    const m = this.$store.state.footer.mode;
    return m;
  }

  mounted () {
    this.$store.commit('FOOTER_VISIBLE', true);
    this.$store.dispatch(`${this.modulename}/load`)
  }

}
